interface Props {
    text: string,
    onClick: () => void,
    pointer?: boolean,
    fontSize?: number,
    uppercase?: boolean,
    border?: boolean,
}

const WhiteButton: React.FC<Props> = ({
    text,
    onClick,
    pointer = true,
    fontSize = 14,
    uppercase = true,
    border = false,
}) => {
    return (
        <button
            className={`w-full h-full rounded-[5px] font-bold text-black ${pointer && "cursor-pointer"} ${uppercase && "uppercase"} text-[${fontSize}px] ${border ? "border border-solid border-black" : "border-none"}`}
            onClick={onClick}
        >
            {text}
        </button>
    )
}

export default WhiteButton
