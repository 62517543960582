import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { Patient } from "../../../../utils/api-objects/Patient";
import { callCreateMealPlanApi } from "../../../../utils/apis/plan-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { usePatients } from "../../../../utils/context/PatientsContext";
import { UploadSection } from "../components/UploadSection";
import { SuccessOverlay } from "../health-plan/components/SuccessOverlay";
import { fields } from "./components/constants";

const MealPlanPage = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const location = useLocation();
    const navigate = useNavigate()

    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get("patient_id");
    const { patientsState } = usePatients()
    const patients = patientsState.patients

    const [patient, setPatient] = useState<Patient | null>(null);
    const [isGeneralLoading, setIsGeneralLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false)
    // const [showImportantParameters, setShowImportantParameters] = useState(false)

    // const [dietFile, setDietFile] = useState<File>()
    // const [shoppingListFile, setShoppingListFile] = useState<File>()
    // const [eatingHabitsFile, setEatingHabitsFile] = useState<File>()
    // const [tipsAndRecepesFile, setTipsAndRecepesFile] = useState<File>()
    // const [seasonalFruitsAndVegetablesFile, setSeasonalFruitsAndVegetablesFile] = useState<File>()

    // const onDietInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const files = (e.target as HTMLInputElement).files
    //     setDietFile(files![0])
    // }

    // const onShoppingListInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const files = (e.target as HTMLInputElement).files
    //     setShoppingListFile(files![0])
    // }

    // const onEatingHabitsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const files = (e.target as HTMLInputElement).files
    //     setEatingHabitsFile(files![0])
    // }

    // const onTipsAndRecepesInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const files = (e.target as HTMLInputElement).files
    //     setTipsAndRecepesFile(files![0])
    // }

    // const onSeasonalFruitsAndVegetablesInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const files = (e.target as HTMLInputElement).files
    //     setSeasonalFruitsAndVegetablesFile(files![0])
    // }

    // const pdfConfigurations = [
    //     { file: dietFile, type: PdfType.Diet, onFileInputChange: onDietInputChange, onFileInputDelete: () => setDietFile(undefined) },
    //     { file: shoppingListFile, type: PdfType.ShoppingList, onFileInputChange: onShoppingListInputChange, onFileInputDelete: () => setShoppingListFile(undefined) },
    //     { file: eatingHabitsFile, type: PdfType.EatingHabits, onFileInputChange: onEatingHabitsInputChange, onFileInputDelete: () => setEatingHabitsFile(undefined) },
    //     { file: tipsAndRecepesFile, type: PdfType.TipsAndRecepes, onFileInputChange: onTipsAndRecepesInputChange, onFileInputDelete: () => setTipsAndRecepesFile(undefined) },
    //     { file: seasonalFruitsAndVegetablesFile, type: PdfType.SeasonalFruitsAndVegetables, onFileInputChange: onSeasonalFruitsAndVegetablesInputChange, onFileInputDelete: () => setSeasonalFruitsAndVegetablesFile(undefined) },
    // ];

    // const [hints, setHints] = useState({
    //     topNutritionRecommendations: "",
    //     topHeroFoods: "",
    //     topIntegratori: "",
    // });

    // const someHintAvailable = hints.topHeroFoods !== "" ||
    //     hints.topIntegratori !== "" ||
    //     hints.topNutritionRecommendations !== ""

    // const somePdfAvailable = dietFile !== undefined ||
    //     shoppingListFile !== undefined ||
    //     eatingHabitsFile !== undefined ||
    //     tipsAndRecepesFile !== undefined ||
    //     seasonalFruitsAndVegetablesFile !== undefined

    // ********************************************************************************

    // const [parameterList, setParameterList] = useState(initialKeyParameters);

    // const [obiettivo, setObiettivo] = useState("");
    // const [acqua, setAcqua] = useState("");
    // const [peso, setPeso] = useState("");
    // const [sonno, setSonno] = useState("");
    // const [energia, setEnergia] = useState("");
    // const [digestione, setDigestione] = useState("");
    // const [esercizio, setEsercizio] = useState("");

    // const someParametersSelected = parameterList.filter(par => par.isSelected === true).length > 0

    // useEffect(() => {
    //     setParameterList([
    //         { name: "obiettivo", label: "🎯 Obiettivo", value: obiettivo, isSelected: false },
    //         { name: "acqua", label: "💧 Acqua", value: acqua, isSelected: false },
    //         { name: "peso", label: "⚖️ Peso", value: `${peso} kg`, isSelected: false },
    //         { name: "sonno", label: "🛌 Sonno", value: sonno, isSelected: false },
    //         { name: "energia", label: "⚡ Energia", value: energia, isSelected: false },
    //         { name: "digestione", label: "🫃 Digestione", value: digestione, isSelected: false },
    //         { name: "esercizio", label: "💪 Esercizio", value: esercizio, isSelected: false },
    //     ]);
    // }, [obiettivo, acqua, peso, sonno, energia, digestione, esercizio]);

    // const publishButtonEnabled =
    //     someHintAvailable || someParametersSelected

    // const handleParameterUpdate = (updatedList: Array<{ name: string; label: string; value: string; isSelected: boolean }>) => {
    //     setParameterList(updatedList);
    // };

    // const handleRemoveParameter = (paramToRemove: { name: string, label: string, value: string, isSelected: boolean }) => {
    //     setParameterList(prevList => {
    //         const updatedList = prevList.map(param =>
    //             param.name === paramToRemove.name
    //                 ? { ...param, isSelected: false }
    //                 : param
    //         );
    //         return updatedList;
    //     });

    //     setSelectedImportantParameters(prevSelected => {
    //         const updatedSelected = prevSelected.filter(param => param.name !== paramToRemove.name);
    //         return updatedSelected;
    //     });
    // };

    // const handleImportantParametersConfirm = (updatedParameterList: Array<{ name: string; label: string; value: string; isSelected: boolean }>) => {
    //     setSelectedImportantParameters(updatedParameterList.filter(par => par.isSelected === true))
    //     setShowImportantParameters(false)
    // };

    // ********************************************************************************

    // const [selectedImportantParameters, setSelectedImportantParameters] = useState<Array<{ name: string, label: string; value: string, isSelected: boolean }>>([]);

    const onPublishClick = async () => {
        if (!idToken || !patientId) return;
        setIsLoading(true);

        const mealPlanData: { [key: string]: string | string[] | null } = Object.fromEntries(
            Object.entries(inputs).map(([key, value]) => [
                key === "guidelines" ? "guidelines" : "recepees",

                value ?
                    value.split("\n")
                        .map((item) => item.replace(/^•\s*/, "").trim())
                        .filter(Boolean)
                    : null,
            ])
        );

        const formData = new FormData();
        formData.append("user_id", patientId);

        // Append the text data fields
        for (const [key, value] of Object.entries(mealPlanData)) {
            if (value !== null) {
                formData.append(key, Array.isArray(value) ? value.join(", ") : value);
            }
        }

        uploadedFiles.mealPlan.forEach((file) => {
            console.log(`Appending mealPlan file: ${file.name}`);
            formData.append("mealPlan", file);
        });

        uploadedFiles.supportMaterials.forEach((file) => {
            console.log(`Appending supportMaterials file: ${file.name}`);
            formData.append("supportMaterials", file);
        });

        try {
            await callCreateMealPlanApi(idToken, formData);
            setSuccess(true)
        } catch (error) {
            console.log("Errore nella creazione del meal plan", error)
        } finally {
            setIsLoading(false);
        }
        // setIsLoading(true)

        // if (someHintAvailable || someParametersSelected) {
        //     if (!patient || !idToken) {
        //         setIsLoading(false);
        //         return;
        //     }
        //     const keys = parameterList.filter(par => par.isSelected === true).map(par => par.name)
        //     callCreateMealPlanApi(
        //         patient,
        //         idToken,
        //         convertToArray(hints.topNutritionRecommendations),
        //         convertToArray(hints.topHeroFoods),
        //         convertToArray(hints.topIntegratori),
        //         keys,
        //         (success) => {
        //             console.log(success);
        //             const mealId: string = success.meal_id
        //             console.log(mealId)
        //             if (somePdfAvailable) {
        //                 publishPdfs(mealId)
        //             }
        //         },
        //         (error) => {
        //             console.log(error);
        //         }
        //     );
        // }
    }

    // const publishPdfs = async (mealId: string) => {
    //     const asyncTasks: Promise<void>[] = [];
    //     console.log("Some PDFs are available, uploading...");

    //     if (dietFile) {
    //         asyncTasks.push(onPublishPdf(PdfType.Diet, dietFile, mealId));
    //     }
    //     if (shoppingListFile) {
    //         asyncTasks.push(onPublishPdf(PdfType.ShoppingList, shoppingListFile, mealId));
    //     }
    //     if (eatingHabitsFile) {
    //         asyncTasks.push(onPublishPdf(PdfType.EatingHabits, eatingHabitsFile, mealId));
    //     }
    //     if (tipsAndRecepesFile) {
    //         asyncTasks.push(onPublishPdf(PdfType.TipsAndRecepes, tipsAndRecepesFile, mealId));
    //     }
    //     if (seasonalFruitsAndVegetablesFile) {
    //         asyncTasks.push(onPublishPdf(PdfType.SeasonalFruitsAndVegetables, seasonalFruitsAndVegetablesFile, mealId));
    //     }

    //     try {
    //         await Promise.all(asyncTasks);

    //         console.log("All operations completed successfully");
    //         navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`);
    //     } catch (error) {
    //         console.error("Error during publish operations:", error);
    //     } finally {
    //         setIsLoading(false); // Ensure loading state is reset regardless of success or failure
    //     }
    // }

    // const onPublishPdf = async (pdfType: PdfType, file: File, mealId: string | null) => {
    //     if (!idToken || mealId === null) return
    //     const filename = `${parseAndCamelCaseString(pdfType)}_${mealId}.pdf`
    //     const response = await fetch(`${BASE_URL}/api/health-plans/patient/${patientId}/upload-url?filename=${filename}&folder=meal_plan/${pdfType.replace(/-/g, '_')}`, requestOptionsGET(idToken))
    //     if (response.ok) {
    //         const urlResponse = await response.json()
    //         const formData = new FormData()
    //         Object.entries(urlResponse.fields).forEach(([k, v]) => {
    //             formData.append(k, v as unknown as string)
    //         })
    //         formData.append('file', file)
    //         const s3response = await axios.post(urlResponse.url, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             }
    //         })
    //         if (s3response.status === 204) {
    //             console.log(`${pdfType} file uploaded successfully`)
    //         } else {
    //             console.log(`${pdfType} file not uploaded`)
    //         }
    //     } else {
    //         const responseJson = await response.json()
    //         console.log(responseJson)
    //     }
    // }

    useEffect(() => {
        if (!patients) {
            setIsGeneralLoading(false);
            return;
        }
        const foundPatient = patients.find((p) => p.user_id === patientId);
        if (!foundPatient) return
        setPatient(foundPatient);
        setIsGeneralLoading(false)

        if (!patientId || !idToken) return
    }, [patients, patientId])

    // useEffect(() => {
    //     const ids = handleQuestionIds()
    //     getAnswersByIdsAndUserId(
    //         ids!,
    //         patientId!,
    //         idToken!,
    //         (answers) => {
    //             setObiettivo(handleObiettivoAnswer(answers.filter(ans => ans.question_id.toString() === ids![0])[0].value[0]))
    //             setAcqua(handleAcquaAnswer(answers.filter(ans => ans.question_id.toString() === ids![1])[0].value[0]))
    //             setPeso(answers.filter(ans => ans.question_id.toString() === ids![2])[0].value[0])
    //             setSonno(handleSonnoAnswer(answers.filter(ans => ans.question_id.toString() === ids![3])[0].value[0]))
    //             setEnergia(handleEnergiaAnswer(answers.filter(ans => ans.question_id.toString() === ids![4])[0].value[0]))
    //             setDigestione(handleDigestioneAnswer(answers.filter(ans => ans.question_id.toString() === ids![5])[0].value[0]))
    //             setEsercizio(handleEsercizioAnswer(answers.filter(ans => ans.question_id.toString() === ids![6])[0].value[0]))
    //         },
    //         (error) => {
    //             console.log(error)
    //         },
    //     )
    // }, [])

    const [inputs, setInputs] = useState<Record<string, string>>({
        guidelines: "",
        recepees: "",
    });

    const [uploadedFiles, setUploadedFiles] = useState<{
        mealPlan: File[];
        supportMaterials: File[];
    }>({
        mealPlan: [],
        supportMaterials: [],
    });

    const isPublishEnabled =
        Object.values(inputs).some((value) => value.trim() !== "") ||
        uploadedFiles.mealPlan.length > 0 ||
        uploadedFiles.supportMaterials.length > 0;

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        // Ensure each new line starts with a bullet point
        const formattedValue = value
            .split("\n")
            .map((line) => (line.startsWith("•") ? line : `• ${line}`))
            .join("\n");

        setInputs((prev) => ({ ...prev, [name]: formattedValue }));
    };

    const onFilesSelect = (files: File[], field: "mealPlan" | "supportMaterials") => {
        setUploadedFiles((prev) => ({
            ...prev,
            [field]: [...prev[field], ...files],
        }));
    };

    const onRemoveFile = (field: "mealPlan" | "supportMaterials", index: number) => {
        setUploadedFiles((prev) => ({
            ...prev,
            [field]: prev[field].filter((_, i) => i !== index),
        }));
    };

    if (isGeneralLoading) {
        return (
            <LoadingSpinner />
        );
    }

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Informazioni sul cliente non trovate
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    return (
        // <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
        //     <div className="w-[89%] flex justify-between my-[20px]">
        //         <div className="space-y-[30px]">
        //             <div className="flex items-center space-x-[100px]">
        //                 <div className="">
        //                     <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
        //                     <div className="font-bold text-[35px] leading-[35px]">Meal Plan</div>
        //                 </div>
        //                 <div className="w-[362px] h-[50px]">
        //                     <PrimaryButton
        //                         text="Salva e pubblica"
        //                         onClick={onPublishClick}
        //                         pointer={publishButtonEnabled}
        //                         disabled={!publishButtonEnabled}
        //                         uppercase={false}
        //                         isLoading={isLoading}
        //                     />
        //                 </div>
        //             </div>
        //             <div className="flex-1 space-y-[25px] pb-[10px]">
        //                 <KeyParametersSection
        //                     onClick={() => { setShowImportantParameters(true) }}
        //                     selectedParameters={selectedImportantParameters}
        //                     onSelectParamClick={handleRemoveParameter}
        //                 />
        //                 <MealPlanHintsSection
        //                     hints={hints}
        //                     setHints={setHints}
        //                 />
        //                 <PdfSection
        //                     pdfConfigurations={pdfConfigurations}
        //                 />
        //             </div>
        //         </div>
        //         <CloseButton onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)} />
        //     </div>
        //     {
        //         isLoading && <LoadingSpinner />
        //     }
        //     {
        //         showImportantParameters &&
        //         <ImportantParametersDialog
        //             onCloseClick={() => setShowImportantParameters(false)}
        //             onConfirmClick={handleImportantParametersConfirm}
        //             onParameterUpdate={handleParameterUpdate} // Pass the function to update the list
        //             parameterList={parameterList}
        //         />
        //     }
        // </div>
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
            <div className="w-full space-y-[30px] pb-[20px] mt-[20px] max-w-[1068px]">
                <div className="w-full flex items-center justify-between">
                    <div className="">
                        <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                        <div className="font-bold text-[35px] leading-[35px]">Meal Plan</div>
                    </div>
                    <div className="flex space-x-[20px]">
                        <div className="w-[266px] h-[50px]">
                            <PrimaryButton
                                text="Salva modifiche"
                                onClick={onPublishClick}
                                pointer={isPublishEnabled}
                                disabled={!isPublishEnabled}
                                uppercase={false}
                                isLoading={isLoading}
                            />
                        </div>
                        <div className="w-[266px] h-[50px]">
                            <WhiteButton
                                text={"Chiudi senza salvare"}
                                onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)}
                                uppercase={false}
                                border={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="space-y-[20px]">
                    {fields.map((field) => (
                        <div key={field.name}>
                            <label htmlFor={field.name} className="block font-bold text-[16px] leading-[33px] mb-2">
                                {field.label}
                            </label>
                            <textarea
                                id={field.name}
                                name={field.name}
                                className="w-full h-[100px] p-[10px] border border-solid border-black rounded-[5px] font-normal text-[16px] leading-[20px] focus:outline-none resize-none placeholder:italic"
                                value={inputs[field.name as keyof typeof inputs] || ""}
                                onChange={handleInputChange}
                                placeholder={field.placeholder}
                            />
                        </div>
                    ))}
                </div>
                <div className="flex w-full space-x-[20px] max-w-[1068px]">
                    <div className="w-full">
                        <UploadSection
                            title="Carica meal plan"
                            description="Nessun meal plan allegato"
                            onFilesSelect={(files) => onFilesSelect(files, "mealPlan")}
                            onRemoveFile={(index) => onRemoveFile("mealPlan", index)}
                            selectedFiles={uploadedFiles.mealPlan}
                        />
                    </div>
                    <div className="w-full">
                        <UploadSection
                            title="🗂️ A supporto del Meal Plan"
                            description="Nessuna file a supporto allegato a questo Meal Plan"
                            onFilesSelect={(files) => onFilesSelect(files, "supportMaterials")}
                            onRemoveFile={(index) => onRemoveFile("supportMaterials", index)}
                            selectedFiles={uploadedFiles.supportMaterials}
                        />
                    </div>
                </div>
            </div>
            {
                success &&
                <SuccessOverlay
                    onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)}
                    type="Meal Plan"
                />
            }
        </div>
    )
}

export default MealPlanPage
