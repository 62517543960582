import { differenceInCalendarDays, isToday, isTomorrow, parseISO } from "date-fns"
import { Appointment } from "../pages/platform/components/Appointment"
import { Patient } from "./api-objects/Patient"
import { SortingItem } from "./api-objects/SortingItem"
import { ENVIRONMENT, question_ids_dev, question_ids_prod } from "./Constants"
import { BloodTestTrackingStatus, Environment, QuestionCategory, TrackingStatus } from "./Enums"

export const handleDate = (date: string) => {
  const realDate = new Date(date)
  const formatter = new Intl.DateTimeFormat('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' });
  return formatter.format(realDate)
}

export const handleDayOfMonth = (date: string) => {
  const realDate = new Date(date)
  const day = realDate.getDate()
  return day
}

export const handleMonth = (date: string) => {
  const realDate = new Date(date)
  const day = realDate.getMonth() + 1
  switch (day) {
    case 1:
      return "gen"
    case 2:
      return "feb"
    case 3:
      return "mar"
    case 4:
      return "apr"
    case 5:
      return "mag"
    case 6:
      return "giu"
    case 7:
      return "lug"
    case 8:
      return "ago"
    case 9:
      return "set"
    case 10:
      return "ott"
    case 11:
      return "nov"
    case 12:
      return "dic"
    default:
      return ""
  }
}

export const handleYear = (date: string) => {
  const realDate = new Date(date)
  const year = realDate.getFullYear()
  return year
}

export const handleHourAndMinutes = (date: string) => {
  const realDate = new Date(date)
  const hour = realDate.getHours()
  const minutes = realDate.getMinutes()
  return `${hour < 10 ? `0${hour.toString()}` : hour}:${minutes < 10 ? `0${minutes.toString()}` : minutes}`
}

export const handleDaysDifference = (date: string) => {
  const today = new Date()
  const futureDate = new Date(date)
  const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 3600 * 24))
  return differenceInDays
}

export const handleHoursDifference = (date: string) => {
  const today = new Date()
  const futureDate = new Date(date)
  const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 3600))
  return differenceInDays
}

export const handleMinutesDifference = (date: string) => {
  const today = new Date()
  const futureDate = new Date(date)
  const differenceInDays = Math.round((futureDate.getTime() - today.getTime()) / (1000 * 60))
  return differenceInDays
}

export const handlePurchasePriority = (patient: Patient) => {
  if (patient.sub_type === "sub-health") {
    if (patient.status !== "active") {
      return "Percorso Health - expired"
    } else {
      return "Percorso Health"
    }
  } else if (patient.sub_type === "sub-nutrition") {
    if (patient.status !== "active") {
      return "Percorso Nutrition - expired"
    } else {
      return "Percorso Nutrition"
    }
  } else if (patient.products.includes("dna-test")) {
    return "DNA Test"
  } else if (patient.products.includes("blood-test")) {
    return "Analisi degli Aminoacidi"
  } else if (patient.products.includes("expert-consult")) {
    return "Consulto con medico"
  } else if (patient.products.includes("nutritionist-consult")) {
    return "Consulto con nutrizionista"
  } else {
    return "--"
  }
}

function filterAppointmentsByDate(appointments: Appointment[], filterDate: Date): Appointment[] {
  return appointments.filter((appointment) => {
    const appointmentDate = new Date(appointment.date); // Replace `date` with the timestamp field in your object
    return appointmentDate.toISOString().split('T')[0] === filterDate.toISOString().split('T')[0];
  });
}

export function getTodayAppointments(appointments: Appointment[]): Appointment[] {
  return filterAppointmentsByDate(appointments, new Date());
}

export function getTomorrowAppointments(appointments: Appointment[]): Appointment[] {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return filterAppointmentsByDate(appointments, tomorrow);
}

export const handleMissingTime = (appointment: Appointment) => {
  if (appointment.remainingDays > 1) {
    return `Mancano ${appointment.remainingDays} giorni`
  } else if (appointment.remainingDays === 1) {
    return "Manca 1 giorno"
  } else {
    if (appointment.remainingHours > 1) {
      return `Mancano ${appointment.remainingHours} ore`
    } else if (appointment.remainingHours === 1 && appointment.remainingMinutes >= 60) {
      return "Manca 1 ora"
    }
    else {
      if (appointment.remainingMinutes > 1) {
        return `Mancano ${appointment.remainingMinutes} minuti`
      } else if (appointment.remainingMinutes === 1) {
        return `Manca 1 minuto`
      } else {
        return `Il meeting è iniziato, connettiti al più presto`
      }
    }
  }
}

export const handleDnaTrackerText = (status: TrackingStatus) => {
  switch (status) {
    case TrackingStatus.Purchased:
      return "Acquistato"
    case TrackingStatus.Delivered:
      return "Kit ricevuto"
    case TrackingStatus.TestDone:
      return "Prelievo effettuato"
    case TrackingStatus.SampleRegistered:
      return "Campione registrato"
    case TrackingStatus.PickupRequested:
      return "Ritiro prenotato"
    case TrackingStatus.Departed:
      return "Ritiro effettuato"
    case TrackingStatus.DeliveredToLaboratory:
      return "Spedito al laboratorio"
    case TrackingStatus.StartingToExtract:
      return "Estrazione iniziata"
    case TrackingStatus.StartingAnalysis:
      return "Analisi iniziata"
    case TrackingStatus.AnalysisDone:
      return "Analisi completa"
    case TrackingStatus.ReportGenerated:
      return "Risultato ricevuto"
    default:
      return "todo TM"
  }
}

export const handleBloodTrackerText = (status: BloodTestTrackingStatus) => {
  switch (status) {
    case BloodTestTrackingStatus.Purchased:
      return "Acquistato"
    case BloodTestTrackingStatus.Delivered:
      return "Kit ricevuto"
    case BloodTestTrackingStatus.TestDone:
      return "Prelievo effettuato"
    case BloodTestTrackingStatus.SampleRegistered:
      return "Campione registrato"
    case BloodTestTrackingStatus.PickupRequested:
      return "Ritiro prenotato"
    case BloodTestTrackingStatus.DeliveredToLab:
      return "Spedito al laboratorio"
    case BloodTestTrackingStatus.ReportGenerated:
      return "Risultato ricevuto"
    default:
      return "todo TM"
  }
}

export const handleMissingDays = (app: Appointment): string => {
  const appointmentDate = parseISO(app.date);
  const today = new Date();

  if (isToday(appointmentDate)) {
    return "oggi";
  }

  if (isTomorrow(appointmentDate)) {
    return "domani";
  }

  const daysDifference = differenceInCalendarDays(appointmentDate, today);

  if (daysDifference > 1) {
    return `tra ${daysDifference} giorni`;
  }

  return "Data non valida";
};

export const fromQuestionCategoryToString = (category: QuestionCategory, includeEmoji: boolean = true) => {
  switch (category) {
    case QuestionCategory.PersonalGoals:
      return `${includeEmoji ? "\u{1F642} " : ""}Personali e obiettivi`;
    case QuestionCategory.FamilyHistoryDiseases:
      return `${includeEmoji ? "\u{1F915} " : ""}Familiarità e patologie`;
    case QuestionCategory.LifestyleSports:
      return `${includeEmoji ? "\u{1F3CB} " : ""}Stile di vita e attività sportiva`;
    case QuestionCategory.DietaryHabits:
      return `${includeEmoji ? "\u{1F959} " : ""}Abitudini alimentari`;
    case QuestionCategory.Notes:
      return `${includeEmoji ? "\u{1F3CB} " : ""}Note`;
  }
}

export const handleObiettivoAnswer = (answer: string) => {
  switch (answer) {
    case "Pesoforma":
      return "Pesoforma"
    case "Migliore alimentazione in generale":
      return "Alimentazione"
    case "Salute gastrointestinale (gonfiore, digestione…)":
      return "Salute Gastrointestinale"
    case "Scoprire e agire su intolleranze":
      return "Intolleranze"
    case "Longevità e prevenzione a 360°":
      return "Prevenzione"
    case "Nutrizione per sport performance":
      return "Sport Performance"
    case "Integratori adatti alle mie esigenze":
      return "Integrazione"
    case "Migliore stato di salute in generale":
      return "Salute generale"
    default:
      return answer
  }
}

export const handleAcquaAnswer = (answer: string) => {
  switch (answer) {
    case "Meno di mezzo litro":
      return "Meno di mezzo litro"
    case "Tra mezzo litro e 1 litro":
      return "Intorno a 1 litro"
    case "Tra 1 litro e 1 litro e mezzo":
      return "Intorno a 1 litro e mezzo"
    case "Tra 1 litro e mezzo e 2 litri":
      return "Intorno a 2 litri"
    case "Oltre 2 litri":
      return "Più di 2 litri"
    default:
      return answer
  }
}

export const handleSonnoAnswer = (answer: string) => {
  switch (answer) {
    case "Buona (7-8 ore, riposante)":
      return "7-8 ore Riposante"
    case "Moderata (5-6 ore o alcuni disturbi)":
      return "5-6 ore Disturbato"
    case "Scarsa (meno di 5 ore o frequenti disturbi)":
      return "< 5 ore Molto disturbato"
    default:
      return answer
  }
}

export const handleEnergiaAnswer = (answer: string) => {
  switch (answer) {
    case "Mai":
      return "Livello ottimo"
    case "Raramente":
      return "Livello buono"
    case "Occasionalmente":
      return "Livello scarso"
    case "Frequentemente":
      return "Livello basso"
    default:
      return answer
  }
}

export const handleDigestioneAnswer = (answer: string) => {
  switch (answer) {
    case "Mai":
      return "Ottima condizione"
    case "Raramente":
      return "Buona condizione"
    case "Occasionalmente":
      return "Scarsa condizione"
    case "Frequentemente":
      return "Bassa condizione"
    default:
      return answer
  }
}

export const handleEsercizioAnswer = (answer: string) => {
  switch (answer) {
    case "Nessuno":
    case "0-60 minuti":
      return "Scarsa frequenza"
    case "60-150 minuti":
      return "Buona frequenza"
    case "Più di 150 minuti":
      return "Ottima frequenza"
    default:
      return answer
  }
}

export const getFormattedDate = () => {
  const now = new Date();
  const yyyy = now.getFullYear();
  const mm = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(now.getDate()).padStart(2, '0');
  const hh = String(now.getHours()).padStart(2, '0');
  const mi = String(now.getMinutes()).padStart(2, '0');
  const ss = String(now.getSeconds()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}_${hh}-${mi}-${ss}`;
};

export const getFormattedDateWithInput = (input: string) => {
  const date = new Date(input);
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const mi = String(date.getMinutes()).padStart(2, '0');
  const ss = String(date.getSeconds()).padStart(2, '0');
  return `${dd}-${mm}-${yyyy}`;
};

export const parseAndCamelCaseString = (input: string): string => {
  return input
      .split('-') // Split the string by hyphens
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each part
      .join(''); // Join the parts together
};

export const convertToArray = (text: string): string[] => {
  return text
      .split("\n")
      .map(line => line.trim())
      .filter(line => line.length > 0); // Remove empty lines
};

export const handleQuestionIds = () => {
  switch (ENVIRONMENT) {
      case Environment.DEVELOP:
          return question_ids_dev
      case Environment.STAGE:
          return []
      case Environment.PRODUCTION:
          return question_ids_prod
        default:
          return []
  }
}

export const addOneHour = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 1); // Add one hour
  return newDate;
};

export const calculateDateRanges = (referenceDate: Date) => {
  const currentMonthStart = new Date(referenceDate.getFullYear(), referenceDate.getMonth(), 1);
  const currentMonthEnd = new Date(referenceDate.getFullYear(), referenceDate.getMonth() + 1, 0, 23, 59, 59, 999);
  const previousMonthStart = new Date(referenceDate.getFullYear(), referenceDate.getMonth() - 1, 1);
  const previousMonthEnd = new Date(referenceDate.getFullYear(), referenceDate.getMonth(), 0, 23, 59, 59, 999);

  const currentWeekStart = new Date(referenceDate);
  currentWeekStart.setDate(referenceDate.getDate() - referenceDate.getDay() + 1); // Monday
  currentWeekStart.setHours(0, 0, 0, 0);

  const currentWeekEnd = new Date(currentWeekStart);
  currentWeekEnd.setDate(currentWeekStart.getDate() + 6); // Sunday
  currentWeekEnd.setHours(23, 59, 59, 999);

  const previousWeekStart = new Date(currentWeekStart);
  previousWeekStart.setDate(currentWeekStart.getDate() - 7);

  const previousWeekEnd = new Date(currentWeekEnd);
  previousWeekEnd.setDate(currentWeekEnd.getDate() - 7);

  return {
      currentMonthStart,
      currentMonthEnd,
      previousMonthStart,
      previousMonthEnd,
      currentWeekStart,
      currentWeekEnd,
      previousWeekStart,
      previousWeekEnd,
  };
};

export const processItems = (items: SortingItem[], dateRanges: ReturnType<typeof calculateDateRanges>) => {
  const {
      currentMonthStart,
      currentMonthEnd,
      previousMonthStart,
      previousMonthEnd,
      currentWeekStart,
      currentWeekEnd,
      previousWeekStart,
      previousWeekEnd,
  } = dateRanges;

  let totalItems = 0;
  let thisMonth = 0, lastMonth = 0;
  let thisWeek = 0, lastWeek = 0;

  for (const item of items) {
      const itemDate = addOneHour(new Date(item.created_at));
      totalItems++;

      if (itemDate >= currentMonthStart && itemDate <= currentMonthEnd) {
          thisMonth++;
      } else if (itemDate >= previousMonthStart && itemDate <= previousMonthEnd) {
          lastMonth++;
      }

      if (itemDate >= currentWeekStart && itemDate <= currentWeekEnd) {
          thisWeek++;
      } else if (itemDate >= previousWeekStart && itemDate <= previousWeekEnd) {
          lastWeek++;
      }
  }

  return { totalItems, thisMonth, lastMonth, thisWeek, lastWeek };
};
