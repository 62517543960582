import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import WhiteButton from "../../../components/buttons/WhiteButton"
import LoadingSpinner from "../../../components/Loader"
import { getAppointmentsByOrganizerEmail } from "../../../utils/apis/booking-service"
import { IMAGE_LOGO } from "../../../utils/Constants"
import { useAppointments } from "../../../utils/context/AppointmentsContext"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { AppointmentStatus } from "../../../utils/Enums"
import { handleDaysDifference, handleHoursDifference, handleMinutesDifference } from "../../../utils/Functions"
import { Appointment } from "../../platform/components/Appointment"

const FetchAppointments = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const { setAppointments, setActiveAppointments, setFutureActiveAppointments, setOldActiveAppointments } = useAppointments()

    const [errorFetchingUserData, setErrorFetchingUserData] = useState(false)

    const getAppointments = () => {
        if (!idToken || !email) return
        getAppointmentsByOrganizerEmail(
            idToken,
            email,
            (appointments) => {
                console.log("Appointments fetched correctly");
                const allAppointments: Appointment[] = []
                for (const appointment of appointments) {
                    allAppointments.push({
                        date: appointment.payload.scheduled_event.start_time,
                        patientName: appointment.payload.name,
                        patientEmail: appointment.email,
                        editUrl: appointment.payload.reschedule_url,
                        connectUrl: appointment.payload.scheduled_event.location.join_url,
                        cancelUrl: appointment.payload.cancel_url,
                        remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
                        remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
                        remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
                        status: appointment.payload.status,
                        expert_email: appointment.organizer_email,
                    })
                }
                const allActiveAppointments = allAppointments.filter(app => app.status === AppointmentStatus.Active)
                const allFutureActiveAppointments = allActiveAppointments.filter(app => app.remainingMinutes > -30)
                const allOldActiveAppointments = allActiveAppointments.filter(app => app.remainingMinutes <= -30)
                setAppointments(allAppointments)
                setActiveAppointments(allActiveAppointments)
                setFutureActiveAppointments(allFutureActiveAppointments)
                setOldActiveAppointments(allOldActiveAppointments)
                navigate("/platform-experts/home")
            },
            (error) => {
                console.log(error)
            }
        )
    }


    useEffect(() => {
        getAppointments()
    }, [])

    // ********************************************************************************

    return (
        <div className="App">
            <div className="w-full h-full flex items-center justify-center">
                <div className="flex flex-col items-center px-[20px] bg-white">
                    <div className="h-[35px] mb-[40px]">
                        <img src={IMAGE_LOGO} alt="Holifya Logo" />
                    </div>
                    {
                        errorFetchingUserData ?
                            <div className="flex flex-col items-center px-[20px]">
                                <div className="font-bold text-[25px] leading-[33px] mb-[20px]">
                                    ❌ Errore
                                </div>
                                <div className="font-normal text-[16px] leading-[24px] text-center mb-[50px]">
                                    Qualcosa è andato storto, ti chiediamo ti riprovare
                                </div>
                                <div className="w-[300px] h-[50px]">
                                    <WhiteButton
                                        text="Torna alla login page"
                                        onClick={() => navigate("/")}
                                    />
                                </div>
                            </div> :
                            <div className="flex flex-col items-center px-[20px]">
                                <div className="font-bold text-[25px] leading-[33px] mb-[20px]">
                                    ⏳ Attendi...
                                </div>
                                <div className="font-normal text-[16px] leading-[24px] text-center mb-[50px]">
                                    Stiamo caricando i tuoi appuntamenti.
                                </div>
                                <div><LoadingSpinner /></div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FetchAppointments
