import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { BASE_URL, requestOptionsGET } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import "./css/WallPage.css"

const WallPage = () => {
  const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    callGetMedicalUserApi()
  }, [])

  // GET users info by user_id
  async function callGetMedicalUserApi() {
    if (!idToken || !userId) return
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/${userId}`, requestOptionsGET(idToken))
    setIsLoading(false)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
      if (responseJson.calendly_data.calendly_invitation_status !== "pending") {
        navigate("/platform-experts")
      }
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  async function callSyncCalendlyApi() {
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/sync-calendly/${userId}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken!,
      },
    })
    setIsLoading(false)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      if (responseJson.calendly_data.calendly_invitation_status !== "pending") {
        navigate("/platform-experts", { state: { userId, idToken } })
      }
    } else {
      const responseInfoJson = await responseUserinfo.json()
      console.log(responseInfoJson)
    }
  }

  return (
    <div className="App">
      <div className="wall-page-full-container">
        <div className="wall-page-internal-container">
          <div className="wall-page-content">
            <div>
              <img src="/images/holifyaLogos/holifya-logo-blue.svg" />
            </div>
            <div className="wall-page-heading">
              Fantastico!
            </div>
            <div className="wall-page-copy">
              Hai ricevuto una mail con l'invito a connetterti al nostro Calendly.
              Prosegui da lì per selezionare le tue disponibilità.
            </div>
            <div className="wall-page-image-container" />
            <button
              className="wall-page-button primary-action-color-background font-bold text-[14px] uppercase"
              onClick={callSyncCalendlyApi}
            >
              Registrazione effettuata
            </button>
            <div className="wall-page-copy">
              Una volta che avrai settato il tuo account collegato Calendly avrai accesso alla tua area personale Holifya.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WallPage
