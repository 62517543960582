export interface MealPlan {
    id: string,
    user_id: string,
    user_email: string,
    user_name: string,
    data: MealPlanData,
    created_at: string,
    updated_at: string,
}

export interface MealPlanData {
    key_parameters: string[],
    nutrition_recommendations: string[],
    top_hero_foods: string[],
    top_integratori: string[],
}

interface DataHistory {
    value: HealthPlanData,
    date: string,
}

export enum HealthPlanPdfType {
    Materials = "materials",
    Prescriptions = "prescriptions",
}

export interface PdfData {
    file_name: string,
    s3_url: string,
    type: HealthPlanPdfType,
}

export interface HealthPlan {
    id: string,
    user_id: string,
    data: HealthPlanData,
    pdf_data: PdfData[],
    updates_history: DataHistory[],
    created_at: string,
    updated_at: string,
}

export interface CheckableItem {
    text: string,
    isChecked: boolean,
}

export interface HealthPlanData {
    comment: string[],
    food_suggested: CheckableItem[],
    integrators_suggested: CheckableItem[],
    check_ups_suggested: CheckableItem[],
}