
const ForgotPassword = () => {
  return (
    <div className="App ">
      <div>TM should do it asap</div>
    </div>
  )
}

export default ForgotPassword
