import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseButton from "../../../../components/buttons/new/CloseButton";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { MealPlan } from "../../../../utils/api-objects/meal-plan";
import { Patient } from "../../../../utils/api-objects/Patient";
import { getAnswersByIdsAndUserId } from "../../../../utils/apis/emr-service";
import { getMealPlanByIdApi } from "../../../../utils/apis/plan-service";
import { initialKeyParameters } from "../../../../utils/Constants";
import { useAuth } from "../../../../utils/context/AuthContext";
import { usePatients } from "../../../../utils/context/PatientsContext";
import { PdfType } from "../../../../utils/Enums";
import { handleAcquaAnswer, handleDigestioneAnswer, handleEnergiaAnswer, handleEsercizioAnswer, handleObiettivoAnswer, handleQuestionIds, handleSonnoAnswer } from "../../../../utils/Functions";
import ImportantParametersDialog from "../../components/ImportantParametersDialog";
import KeyParametersSection from "./../components/KeyParametersSection";
import MealPlanHintsSection from "./../components/MealPlanHintsSection";
import { PdfSection } from "./../components/PdfSection";

const EditMealPlanPage = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const location = useLocation();
    const navigate = useNavigate()

    const searchParams = new URLSearchParams(location.search);
    const planId = searchParams.get("meal_plan");
    const patientId = searchParams.get("patient_id");
    const { patientsState } = usePatients()
    const patients = patientsState.patients

    const [isGeneralLoading, setIsGeneralLoading] = useState(true)
    const [patient, setPatient] = useState<Patient | null>(null);
    const [mealPlan, setMealPlan] = useState<MealPlan | null>(null)

    const [hints, setHints] = useState({
        topNutritionRecommendations: "",
        topHeroFoods: "",
        topIntegratori: "",
    });

    const [showImportantParameters, setShowImportantParameters] = useState(false)

    const [parameterList, setParameterList] = useState(initialKeyParameters);

    useEffect(() => {
        if (!planId || !idToken || !patients) return
        getMealPlanByIdApi(
            idToken,
            planId,
            (mealPlans) => {
                setHints({
                    topNutritionRecommendations: mealPlans[0].data.nutrition_recommendations.join().replace(/,/g, '\n'),
                    topHeroFoods: mealPlans[0].data.top_hero_foods.join().replace(/,/g, '\n'),
                    topIntegratori: mealPlans[0].data.top_integratori.join().replace(/,/g, '\n'),
                })
                setMealPlan(mealPlan)
                const updatedParameters = parameterList.map(param => ({
                    ...param,
                    isSelected: mealPlans[0].data.key_parameters.includes(param.name)
                }));
                setParameterList(updatedParameters);
                retrieveAnswers()

                setIsGeneralLoading(false)
            },
            (error) => { console.log(error) }
        )
    }, [])

    useEffect(() => {
        if (!patients) return
        const foundPatient = patients.find((p) => p.user_id === patientId);
        if (!foundPatient) return
        setPatient(foundPatient);
    })

    const [obiettivo, setObiettivo] = useState("");
    const [acqua, setAcqua] = useState("");
    const [peso, setPeso] = useState("");
    const [sonno, setSonno] = useState("");
    const [energia, setEnergia] = useState("");
    const [digestione, setDigestione] = useState("");
    const [esercizio, setEsercizio] = useState("");

    const handleRemoveParameter = (paramToRemove: { name: string, label: string, value: string, isSelected: boolean }) => {
        setParameterList(prevList => {
            const updatedList = prevList.map(param =>
                param.name === paramToRemove.name
                    ? { ...param, isSelected: false }
                    : param
            );
            return updatedList;
        });
    };

    useEffect(() => {
        setParameterList((prevList) =>
            prevList.map((param) => {
                let newValue = param.value;
                switch (param.name) {
                    case "obiettivo":
                        newValue = obiettivo;
                        break;
                    case "acqua":
                        newValue = acqua;
                        break;
                    case "peso":
                        newValue = `${peso} kg`;
                        break;
                    case "sonno":
                        newValue = sonno;
                        break;
                    case "energia":
                        newValue = energia;
                        break;
                    case "digestione":
                        newValue = digestione;
                        break;
                    case "esercizio":
                        newValue = esercizio;
                        break;
                    default:
                        break;
                }
                return { ...param, value: newValue };
            })
        );
    }, [obiettivo, acqua, peso, sonno, energia, digestione, esercizio]);

    const retrieveAnswers = () => {
        const ids = handleQuestionIds()
        if (!patientId || !idToken) return
        getAnswersByIdsAndUserId(
            ids,
            patientId,
            idToken,
            (answers) => {
                setObiettivo(handleObiettivoAnswer(answers.filter(ans => ans.question_id.toString() === ids![0])[0].value[0]))
                setAcqua(handleAcquaAnswer(answers.filter(ans => ans.question_id.toString() === ids![1])[0].value[0]))
                setPeso(answers.filter(ans => ans.question_id.toString() === ids![2])[0].value[0])
                setSonno(handleSonnoAnswer(answers.filter(ans => ans.question_id.toString() === ids![3])[0].value[0]))
                setEnergia(handleEnergiaAnswer(answers.filter(ans => ans.question_id.toString() === ids![4])[0].value[0]))
                setDigestione(handleDigestioneAnswer(answers.filter(ans => ans.question_id.toString() === ids![5])[0].value[0]))
                setEsercizio(handleEsercizioAnswer(answers.filter(ans => ans.question_id.toString() === ids![6])[0].value[0]))
            },
            (error) => {
                console.log(error)
            },
        )
    }

    const [dietFile, setDietFile] = useState<File>()
    const [shoppingListFile, setShoppingListFile] = useState<File>()
    const [eatingHabitsFile, setEatingHabitsFile] = useState<File>()
    const [tipsAndRecepesFile, setTipsAndRecepesFile] = useState<File>()
    const [seasonalFruitsAndVegetablesFile, setSeasonalFruitsAndVegetablesFile] = useState<File>()

    const onDietInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        setDietFile(files![0])
    }

    const onShoppingListInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        setShoppingListFile(files![0])
    }

    const onEatingHabitsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        setEatingHabitsFile(files![0])
    }

    const onTipsAndRecepesInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        setTipsAndRecepesFile(files![0])
    }

    const onSeasonalFruitsAndVegetablesInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        setSeasonalFruitsAndVegetablesFile(files![0])
    }

    const pdfConfigurations = [
        { file: dietFile, type: PdfType.Diet, onFileInputChange: onDietInputChange, onFileInputDelete: () => setDietFile(undefined) },
        { file: shoppingListFile, type: PdfType.ShoppingList, onFileInputChange: onShoppingListInputChange, onFileInputDelete: () => setShoppingListFile(undefined) },
        { file: eatingHabitsFile, type: PdfType.EatingHabits, onFileInputChange: onEatingHabitsInputChange, onFileInputDelete: () => setEatingHabitsFile(undefined) },
        { file: tipsAndRecepesFile, type: PdfType.TipsAndRecepes, onFileInputChange: onTipsAndRecepesInputChange, onFileInputDelete: () => setTipsAndRecepesFile(undefined) },
        { file: seasonalFruitsAndVegetablesFile, type: PdfType.SeasonalFruitsAndVegetables, onFileInputChange: onSeasonalFruitsAndVegetablesInputChange, onFileInputDelete: () => setSeasonalFruitsAndVegetablesFile(undefined) },
    ];

    if (isGeneralLoading) {
        return (
            <LoadingSpinner />
        )
    }

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Informazioni sul cliente non trovate
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
            <div className="w-[89%] flex justify-between my-[20px]">
                <div className="space-y-[30px]">
                    <div className="flex items-center space-x-[100px]">
                        <div className="">
                            <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                            <div className="font-bold text-[35px] leading-[35px]">Edit Meal Plan</div>
                        </div>
                        <div className="w-[362px] h-[50px]">
                            <PrimaryButton
                                text="Aggiorna e pubblica"
                                onClick={() => { }}
                                pointer={false}
                                disabled={true}
                                uppercase={false}
                                isLoading={false}
                            />
                        </div>
                    </div>
                    <div className="flex-1 space-y-[25px] pb-[10px]">
                        <KeyParametersSection
                            onClick={() => setShowImportantParameters(true)}
                            selectedParameters={parameterList.filter(param => param.isSelected === true)}
                            onSelectParamClick={handleRemoveParameter}
                        />
                        <MealPlanHintsSection
                            hints={hints}
                            setHints={setHints}
                        />
                        <PdfSection
                            pdfConfigurations={pdfConfigurations}
                        />
                    </div>
                </div>
                <CloseButton onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)} />
            </div>
            {
                showImportantParameters &&
                <ImportantParametersDialog
                    onCloseClick={() => setShowImportantParameters(false)}
                    onConfirmClick={() => { }}
                    onParameterUpdate={() => { }}
                    parameterList={parameterList}
                />
            }
        </div>
    )
}

export default EditMealPlanPage
