import { FormEvent, useState } from "react"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import PasswordInputElement from "../../components/inputs/PasswordInputElement"
import TextInputElement from "../../components/inputs/TextInputElement"
import LoadingSpinner from "../../components/Loader"
import { logoutAPI } from "../../utils/apis/auth-service"
import { syncCalendlyApi, updateMedicalUserApi } from "../../utils/apis/medical-user-service"
import { DEFAULT_SKILLS, PASSWORD_REGEX } from "../../utils/Constants"
import { useAuth } from "../../utils/context/AuthContext"
import { useUserData } from "../../utils/context/UserDataContext"
import { CompleteAccountPageState, ExpertRole, PasswordInputIcon, PasswordInputType } from "../../utils/Enums"
import SkillCard from "../loginFlow/registration/components/SkillCard"

const Account = () => {
  const { auth, logout } = useAuth()
  const idToken = auth.idToken
  const userId = auth.userId
  const role = auth.role
  const { userData } = useUserData()
  const email = userData.email

  const [isLoading, setIsLoading] = useState(false)

  const onSyncCalendlyClick = () => {
    if (!idToken || !userId) return
    setIsLoading(true)
    syncCalendlyApi(
      idToken,
      userId,
      (success) => {
        console.log(success)
        setIsLoading(false)
      },
      (error) => {
        console.log(error)
        setIsLoading(false)
      }
    )
  }

  const onLogoutButtonClick = () => {
    email && logoutAPI(
      email,
      (success) => {
        console.log(success)
        logout()
      },
      (error) => {
        console.log(error)
      },
    )
  }

  // ********************************************************************************

  return (
    <div className='w-full h-full pl-[70px]'>
      {/* Header */}
      <div className="w-[89%] flex justify-between items-center my-[30px]">
        <div className="font-bold text-[35px] leading-[35px]">
          Account
        </div>
        <button
          className="w-[150px] h-[55px] rounded-[5px] cursor-pointer font-bold text-[16px] leading-[23px] uppercase text-white bg-[#F60A0A] hover:bg-[#E50909] active:bg-[#C10909]"
          onClick={onLogoutButtonClick}
        >
          <div>
            Log out
          </div>
        </button>
      </div>

      {/* Content */}
      <div className="w-full flex-1 pb-[20px]">
        <div className="w-[89%] h-full flex flex-col space-y-[35px]">
          {
            role && role !== ExpertRole.Admin && <div className="w-[408px] h-[55px]">
              <PrimaryButton
                text="Sincronizza Calendly"
                pointer={true}
                disabled={false}
                onClick={onSyncCalendlyClick}
              />
            </div>
          }
          <UserInfoForm />
          <ModifyPasswordForm />
          {
            role && role !== ExpertRole.Admin && <ProfessionalInfoForm />
          }
        </div>
      </div>
      {
        isLoading && <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <LoadingSpinner />
        </div>
      }
    </div>
  )
}

export default Account

const UserInfoForm = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const userId = auth.userId
  const { userData, updateUserData, updateProfessionalUserData } = useUserData()
  const name = userData.name
  const familyName = userData.familyName
  const email = userData.email
  const phoneNumber = userData.phoneNumber
  const sex = userData.sex
  const bio = userData.bio
  const professionalOrder = userData.professional_order
  const professionalOrderNumber = userData.professional_order_number
  const professionalOrderState = userData.professional_order_state
  const tags = userData.tags

  const [userInfoFormData, setUserInfoFormData] = useState({
    name: name || '',
    familyName: familyName || '',
    email: email || '',
    fiscalCode: '',
    phoneNumber: phoneNumber || '',
  });

  const [formChanged, setFormChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserInfoFormData((prev) => ({ ...prev, [name]: value }));
    setFormChanged(true);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!idToken || !userId) return
    setIsLoading(true)
    updateMedicalUserApi(
      idToken,
      userId,
      userInfoFormData.name,
      userInfoFormData.familyName,
      userInfoFormData.phoneNumber,
      sex === "M" ? "Dott." : "Dott.ssa",
      professionalOrder!,
      professionalOrderNumber!,
      professionalOrderState!,
      tags!,
      bio!,
      (user) => {
        setIsLoading(false)
        updateUserData(
          user.data.name,
          user.data.family_name,
          user.email,
          user.data.phone_number,
          user.data.sex,
        )
        updateProfessionalUserData(
          user.data.bio,
          user.data.professional_order,
          user.data.professional_order_number,
          user.data.professional_order_state,
          user.tags,
        )
      },
      (error) => {
        setIsLoading(false)
        console.log(error)
      },
    )
  };

  return (
    <div className="relative">
      <form
        className="w-[408px] space-y-[20px]"
        onSubmit={handleSubmit}
      >
        <TextInputElement
          name="name"
          label="Nome"
          placeholder="Nome"
          inputValue={userInfoFormData.name}
          showLabel={true}
          onChange={handleInputChange}
        />
        <TextInputElement
          name="familyName"
          label="Cognome"
          placeholder="Cognome"
          inputValue={userInfoFormData.familyName}
          showLabel={true}
          onChange={handleInputChange}
        />
        <TextInputElement
          name="email"
          label="Indirizzo e-mail"
          placeholder="Indirizzo e-mail"
          inputValue={userInfoFormData.email}
          showLabel={true}
          onChange={handleInputChange}
          disabled={true}
        />
        <TextInputElement
          name="fiscalCode"
          label="Codice Fiscale"
          placeholder="XXXYYYZZXZZYXXXZ"
          inputValue={userInfoFormData.fiscalCode}
          showLabel={true}
          onChange={handleInputChange}
        />
        <TextInputElement
          name="phoneNumber"
          label="Numero di telefono"
          placeholder="Phone Number"
          inputValue={userInfoFormData.phoneNumber}
          showLabel={true}
          onChange={handleInputChange}
        />
        <div className="w-full h-[55px]">
          <PrimaryButton
            text="Conferma"
            pointer={formChanged}
            disabled={!formChanged} />
        </div>
      </form>
      {
        isLoading && <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <LoadingSpinner />
        </div>
      }
    </div>
  )
}

const ModifyPasswordForm = () => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const [formData, setFormData] = useState({
    password: '',
    newPassword: '',
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setShowPopup(true)
  }

  const [showPopup, setShowPopup] = useState(false)

  const onCloseClick = () => {
    setShowPopup(false)
    formData.password = ""
    formData.newPassword = ""
  }

  const passwordsEqual = formData.password !== "" && formData.newPassword !== "" && formData.password === formData.newPassword
  const submitButtonEnabled = PASSWORD_REGEX.test(formData.password) && PASSWORD_REGEX.test(formData.newPassword) && !passwordsEqual

  return (
    <div className="relative">
      <form
        className={`w-[408px] space-y-[20px]`}
        onSubmit={handleSubmit}
      >
        <div className="font-bold text-[20px] leading-[40px]">
          Modifica password
        </div>
        <PasswordInputElement
          name="password"
          label="Password"
          placeholder="Password Attuale"
          inputValue={formData.password}
          disabled={false}
          image={PasswordInputIcon.ShowDisabled}
          type={PasswordInputType.Password}
          errorText={(!PASSWORD_REGEX.test(formData.password) && formData.password !== "") ? "La password non rispetta i criteri di validità" : ""}
          toggleTypeEnabled={formData.password !== ""}
          onChange={handleInputChange}
        />
        <div className="w-[408px] font-semibold text-[12px] leading-[22px] text-holifya-blue">
          Una password valida è composta da almeno 8 caratteri di cui: almeno una Maiuscola, una minuscola, un numero e un simbolo speciale (!$%& ...)
        </div>
        <PasswordInputElement
          name="newPassword"
          label="Nuova Password"
          placeholder="Nuova password"
          inputValue={formData.newPassword}
          disabled={!PASSWORD_REGEX.test(formData.password)}
          image={PasswordInputIcon.ShowDisabled}
          type={PasswordInputType.Password}
          errorText={(!PASSWORD_REGEX.test(formData.newPassword) && formData.newPassword !== "") ? "La password non rispetta i criteri di validità" : passwordsEqual ? "Le due password devono essere diverse" : ""}
          toggleTypeEnabled={formData.newPassword !== ""}
          onChange={handleInputChange}
        />
        <div className="w-full h-[55px]">
          <PrimaryButton
            text="Modifica password"
            pointer={submitButtonEnabled}
            disabled={!submitButtonEnabled}
          />
        </div>
      </form>
      {
        showPopup && <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-[15px] shadow-lg flex flex-col w-[90%] space-y-[10px] md:w-[362px]">
            <div className="flex flex-col text-holifya-blue">
              <div>Password Vecchia: {formData.password}</div>
              <div>Password Vecchia: {formData.newPassword}</div>
            </div>
            <div className="font-bold text-[10px]">
              Purtroppo al momento non è possibile modificare la password
            </div>
            <div className="w-full h-[50px]">
              <PrimaryButton
                text={"Chiudi"}
                pointer={true}
                disabled={false}
                onClick={onCloseClick}
              />
            </div>
          </div>
        </div>
      }
    </div>
  )
}


const ProfessionalInfoForm = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const userId = auth.userId
  const { userData, updateUserData, updateProfessionalUserData } = useUserData()
  const name = userData.name
  const familyName = userData.familyName
  const phoneNumber = userData.phoneNumber
  const sex = userData.sex
  const bio = userData.bio
  const professionalOrder = userData.professional_order
  const professionalOrderNumber = userData.professional_order_number
  const professionalOrderState = userData.professional_order_state
  const tags = userData.tags

  const [formChanged, setFormChanged] = useState(false)

  const [formData, setFormData] = useState({
    titolo: sex ? (sex === "F" ? "Dott.ssa" : "Dott.") : '',
    professionalOrder: professionalOrder || '',
    professionalOrderNumber: professionalOrderNumber || '',
    professionalOrderState: professionalOrderState || '',
    tags: tags || [""],
    bio: bio || '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormChanged(true);
  };

  const [skills, setSkills] = useState(DEFAULT_SKILLS)
  const [skillsSelected, setSkillsSelected] = useState(tags)
  const [newSkill, setNewSkill] = useState("");

  const onSkillCardClick = (skill: string) => {
    handleSelectedSkillsArray(skill)
    setFormChanged(true)
  }

  const handleSelectedSkillsArray = (skill: string) => {
    if (!skillsSelected) return
    const index = skillsSelected.indexOf(skill);
    if (index > -1) {
      setSkillsSelected([...skillsSelected.slice(0, index), ...skillsSelected.slice(index + 1)]);
    } else {
      setSkillsSelected([...skillsSelected, ...[skill]]);
    }
  }

  const [pageState, setPageState] = useState(CompleteAccountPageState.NoEdit)

  const onAddNewSkillClicked = () => {
    if (pageState === CompleteAccountPageState.NoEdit) {
      setPageState(CompleteAccountPageState.Edit);
      return;
    }

    if (pageState === CompleteAccountPageState.Edit) {
      if (newSkill.trim() !== "") {
        setSkills((prevSkills) => [...prevSkills, newSkill]);
        handleSelectedSkillsArray(newSkill);
        setNewSkill("");
        setFormChanged(true);
      }

      setPageState(CompleteAccountPageState.NoEdit);
    }
  };

  const [isLoading, setIsLoading] = useState(false)

  const onChangeExpertInfoClicked = () => {
    if (!idToken || !userId) return
    setIsLoading(true)
    updateMedicalUserApi(
      idToken,
      userId,
      name!,
      familyName!,
      phoneNumber!,
      formData.titolo,
      formData.professionalOrder,
      formData.professionalOrderNumber,
      formData.professionalOrderState,
      formData.tags,
      formData.bio,
      (user) => {
        setIsLoading(false)
        updateUserData(
          user.data.name,
          user.data.family_name,
          user.email,
          user.data.phone_number,
          user.data.sex,
        )
        updateProfessionalUserData(
          user.data.bio,
          user.data.professional_order,
          user.data.professional_order_number,
          user.data.professional_order_state,
          user.tags,
        )
      },
      (error) => {
        setIsLoading(false)
        console.log(error)
      },
    )
  }

  // ********************************************************************************

  return (
    <div className="relative">
      <div className="w-[408px] space-y-[20px]">
        <div className="font-semibold text-[16px] leading-[23px] pb-[10px] border-b border-solid border-black">
          Informazioni professionali
        </div>
        <TextInputElement
          name="titolo"
          label="Titolo"
          placeholder="Titolo"
          inputValue={formData.titolo}
          onChange={handleInputChange}
          showLabel={true}
        />
        <TextInputElement
          name="professionalOrder"
          label="Iscritto all'ordine"
          placeholder="Ordine"
          inputValue={formData.professionalOrder}
          onChange={handleInputChange}
          showLabel={true}
        />
        <TextInputElement
          name="professionalOrderNumber"
          label="Numero di iscrizione all'ordine"
          placeholder="Numero di iscrizione"
          inputValue={formData.professionalOrderNumber}
          onChange={handleInputChange}
          showLabel={true}
        />
        <TextInputElement
          name="professionalOrderState"
          label="Ordine di"
          placeholder="Provincia dell'ordine"
          inputValue={formData.professionalOrderState}
          onChange={handleInputChange}
          showLabel={true}
        />
        <div className="w-full space-y-[10px]">
          <div className="font-semibold text-[12px] leading-[14px] uppercase">
            Aree di competenza
          </div>
          <div className="flex flex-wrap">
            {skills.map((skill) => (
              <SkillCard
                key={skills.indexOf(skill)}
                skill={skill}
                onClick={onSkillCardClick}
                isChecked={skillsSelected!.includes(skill)}
              />
            ))}
            {
              skillsSelected!.map((skill, index) => (
                !skills.includes(skill) ?
                  <SkillCard
                    key={index}
                    skill={skill}
                    onClick={onSkillCardClick}
                    isChecked={skillsSelected!.includes(skill)}
                  /> : null
              ))
            }
          </div>
          {
            pageState === "edit" ?
              <div className="flex flex-col w-[408px]">
                <label className="font-semibold text-[12px] uppercase">
                  Specializzazione
                </label>
                <div className="flex flex-col border-b border-solid border-black items-end">
                  <input
                    className="border-none w-[98%] h-[40px] text-[16px] font-normal focus:outline-none text-[16px] font-normal"
                    type="text"
                    placeholder="Inserisci nuova specializzazione"
                    onChange={
                      (e) => setNewSkill(e.target.value)
                    }
                  />
                </div>
              </div> : null
          }
          <button
            className={`w-[408px] h-[55px] border-none rounded-[5px] text-white cursor-pointer ${pageState === CompleteAccountPageState.NoEdit ? "ternary-action-color-background" : "primary-action-color-background"} font-bold text-[14px] uppercase`}
            onClick={onAddNewSkillClicked}
          >
            Aggiungi nuova
          </button>
        </div>
        <div>
          <div className="font-semibold text-[12px] leading-[14px] uppercase">
            Bio
          </div>
          <div>
            <textarea
              className="w-[398px] h-max border-b border-solid border-black resize-none max-w-full overflow-y-auto scrollbar-hide p-[5px] focus:outline-none"
              value={formData.bio}
              name="bio"
              onChange={(e) => {
                if (e.target.value.length <= 700) {
                  handleInputChange(e);
                } else {
                  console.log(e.target.value)
                }
              }}
              placeholder="Racconta che professionista sei in massimo 700 caratteri"
            />
            <div className="flex flex-end font-bold text-[12px] leading-[26px]">{formData.bio.split(" ").join("").length} su 700</div>
          </div>
        </div>
        <button
          className={`w-[408px] h-[55px] border-none rounded-[5px] text-white font-bold text-[14px] uppercase primary-action-color-background ${formChanged ? "cursor-pointer" : ""
            }`}
          disabled={!formChanged}
          onClick={onChangeExpertInfoClicked}
        >
          Conferma
        </button>
      </div>
      {
        isLoading && <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <LoadingSpinner />
        </div>
      }
    </div>
  );
};
