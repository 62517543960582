import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { Patient } from "../../../../utils/api-objects/Patient";
import { callCreateHealthPlanApi } from "../../../../utils/apis/plan-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { usePatients } from "../../../../utils/context/PatientsContext";
import { UploadSection } from "../components/UploadSection";
import { fields } from "./components/constants";
import { SuccessOverlay } from "./components/SuccessOverlay";

const HealthPlanPage = () => {
    const { auth } = useAuth()
    const { patientsState } = usePatients()
    const patients = patientsState.patients
    const idToken = auth.idToken
    const location = useLocation();
    const navigate = useNavigate()

    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get("patient_id");

    const [patient, setPatient] = useState<Patient | null>(null);
    const [isGeneralLoading, setIsGeneralLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false)
    const [inputs, setInputs] = useState<Record<string, string>>({
        commento: "",
        alimenti: "",
        integratori: "",
        checkup: "",
    });

    const [uploadedFiles, setUploadedFiles] = useState<{
        materials: File[];
        prescriptions: File[];
    }>({
        materials: [],
        prescriptions: [],
    });

    useEffect(() => {
        if (!patients) {
            setIsGeneralLoading(false);
            return;
        }
        const foundPatient = patients.find((p) => p.user_id === patientId);
        if (foundPatient) {
            setPatient(foundPatient);
        }
        setIsGeneralLoading(false)
    }, [patients, patientId])

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        // Ensure each new line starts with a bullet point
        const formattedValue = value
            .split("\n")
            .map((line) => (line.startsWith("•") ? line : `• ${line}`))
            .join("\n");

        setInputs((prev) => ({ ...prev, [name]: formattedValue }));
    };

    const onFilesSelect = (files: File[], field: "materials" | "prescriptions") => {
        setUploadedFiles((prev) => ({
            ...prev,
            [field]: [...prev[field], ...files],
        }));
    };

    const onRemoveFile = (field: "materials" | "prescriptions", index: number) => {
        setUploadedFiles((prev) => ({
            ...prev,
            [field]: prev[field].filter((_, i) => i !== index),
        }));
    };


    const onPublishClick = async () => {
        if (!idToken || !patientId) return;
        setIsLoading(true);

        const healthPlanData: { [key: string]: string | string[] | null } = Object.fromEntries(
            Object.entries(inputs).map(([key, value]) => [
                key === "commento" ? "comment" :
                    key === "alimenti" ? "food_suggested" :
                        key === "integratori" ? "integrators_suggested" : "check_ups_suggested",

                value ?
                    value.split("\n")
                        .map((item) => item.replace(/^•\s*/, "").trim())
                        .filter(Boolean)
                    : null,
            ])
        );

        // Prepare FormData object to send both form fields and files
        const formData = new FormData();
        formData.append("user_id", patientId);

        // Append the text data fields
        for (const [key, value] of Object.entries(healthPlanData)) {
            if (value !== null) {
                formData.append(key, Array.isArray(value) ? value.join(", ") : value);
            }
        }

        uploadedFiles.materials.forEach((file) => {
            console.log(`Appending material file: ${file.name}`);
            formData.append("materials", file);
        });

        uploadedFiles.prescriptions.forEach((file) => {
            console.log(`Appending prescription file: ${file.name}`);
            formData.append("prescriptions", file);
        });

        try {
            await callCreateHealthPlanApi(idToken, formData);
            setSuccess(true)
        } catch (error) {
            console.log("Errore nella creazione dell'health plan", error)
        } finally {
            setIsLoading(false);
        }
    };

    const isPublishEnabled =
        Object.values(inputs).some((value) => value.trim() !== "") ||
        uploadedFiles.materials.length > 0 ||
        uploadedFiles.prescriptions.length > 0;

    if (isGeneralLoading) {
        return (
            <LoadingSpinner />
        );
    }

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Informazioni sul cliente non trovate
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
            <div className="w-full space-y-[30px] pb-[20px] mt-[20px] max-w-[1068px]">
                <div className="w-full flex items-center justify-between">
                    <div className="">
                        <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                        <div className="font-bold text-[35px] leading-[35px]">Health Plan</div>
                    </div>
                    <div className="flex space-x-[20px]">
                        <div className="w-[266px] h-[50px]">
                            <PrimaryButton
                                text="Salva modifiche"
                                onClick={onPublishClick}
                                pointer={isPublishEnabled}
                                disabled={!isPublishEnabled}
                                uppercase={false}
                                isLoading={isLoading}
                            />
                        </div>
                        <div className="w-[266px] h-[50px]">
                            <WhiteButton
                                text={"Chiudi senza salvare"}
                                onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)}
                                uppercase={false}
                                border={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="space-y-[20px]">
                    {fields.map((field) => (
                        <div key={field.name}>
                            <label htmlFor={field.name} className="block font-bold text-[16px] leading-[33px] mb-2">
                                {field.label}
                            </label>
                            <textarea
                                id={field.name}
                                name={field.name}
                                className="w-full h-[100px] p-[10px] border border-solid border-black rounded-[5px] font-normal text-[16px] leading-[20px] focus:outline-none resize-none placeholder:italic"
                                value={inputs[field.name as keyof typeof inputs] || ""}
                                onChange={handleInputChange}
                                placeholder={field.placeholder}
                            />
                        </div>
                    ))}
                </div>
                <div className="flex w-full space-x-[20px] max-w-[1068px]">
                    <div className="w-full">
                        <UploadSection
                            title="🗂️ Carica materiali di approfondimento"
                            description="Nessun materiale allegato a questo Health Plan"
                            onFilesSelect={(files) => onFilesSelect(files, "materials")}
                            onRemoveFile={(index) => onRemoveFile("materials", index)}
                            selectedFiles={uploadedFiles.materials} // Pass selected files to UploadSection
                        />
                    </div>
                    <div className="w-full">
                        <UploadSection
                            title="📄 Carica prescrizioni"
                            description="Nessuna prescrizione allegata a questo Health Plan"
                            onFilesSelect={(files) => onFilesSelect(files, "prescriptions")}
                            onRemoveFile={(index) => onRemoveFile("prescriptions", index)}
                            selectedFiles={uploadedFiles.prescriptions} // Pass selected files to UploadSection
                        />
                    </div>
                </div>
            </div>
            {
                success &&
                <SuccessOverlay
                    onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)}
                    type="Health Plan"
                />
            }
        </div>
    )
}

export default HealthPlanPage
