import { FormEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import TextInputElement from "../../../components/inputs/TextInputElement"
import LoadingSpinner from "../../../components/Loader"
import { CalendlyExpert } from "../../../utils/api-objects/CalendlyExpert"
import { Patient } from "../../../utils/api-objects/Patient"
import { getMedicaluserByRole } from "../../../utils/apis/medical-user-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { usePatients } from "../../../utils/context/PatientsContext"
import { ExpertRole } from "../../../utils/Enums"
import { handlePurchasePriority } from "../../../utils/Functions"

const Customers = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const role = auth.role
  const { patientsState } = usePatients()
  const patients = patientsState.patients

  const navigate = useNavigate()

  const onSearchClick = () => {
    if (!patients) return
    setIsLoading(true)
    setTimeout(() => {
      const filtered = patients.filter((patient) => {
        const fullName = `${patient.user_name} ${patient.user_family_name}`.toLowerCase()
        const email = patient.email.toLowerCase()
        const swabcode = patient.swabcode || ""
        const lastBloodcode = patient.bloodcodes[patient.bloodcodes.length - 1] || ""
        return (
          fullName.includes(filter.toLowerCase()) ||
          email.includes(filter.toLowerCase()) ||
          swabcode.includes(filter) ||
          lastBloodcode.includes(filter)
        )
      })
      setFilteredPatients(filtered)
      setIsLoading(false)
    }, 200)
  }

  const onResetFilterClick = () => {
    setFilteredPatients(patients!)
    setFilter("")
  }

  const [selectedNutritionist, setSelectedNutritionist] = useState<string | null>(null);

  const onNutritionistClick = (name: string, email: string) => {
    if (!patients) return
    setSelectedNutritionist(name);
    setShowDropdown(false)

    const filtered = patients.filter(patient => patient.nutritionist?.email === email);
    setFilteredPatients(filtered);
  };

  const onResetNutritionistsClick = () => {
    setShowDropdown(false)
    setSelectedNutritionist(null)
    setFilteredPatients(patients!)
  }

  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState("")
  const [filteredPatients, setFilteredPatients] = useState<Patient[]>([])

  const [showDropdown, setShowDropdown] = useState(false);

  const [nutritionists, setNutritionists] = useState<CalendlyExpert[]>([])

  const getNutritionists = async () => {
    if (!idToken) return
    try {
      const nutritionists = await getMedicaluserByRole(idToken, ExpertRole.Nutritionist)
      if (nutritionists !== null) {
        setNutritionists(nutritionists)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!patients) return
    setFilteredPatients(patients)
    if (role === ExpertRole.Admin) {
      getNutritionists()
    }
  }, [patients])

  // ********************************************************************************

  return (
    <div className='w-full h-full flex flex-col pl-[70px]'>
      <div className="font-bold text-[35px] leading-[55px] my-[30px]">
        Clienti
      </div>
      <div className="w-[89%] flex flex-col flex-1 space-y-[40px]">
        <SearchBox
          onClick={onSearchClick}
          isLoading={isLoading}
          filterInput={filter}
          onChange={(e) => setFilter(e.target.value)}
          shouldShowResetButton={filteredPatients !== patients && selectedNutritionist === null}
          onResetClick={onResetFilterClick}
        />
        {
          role === ExpertRole.Admin &&
          <div className="w-full shadow-platform flex items-center justify-between px-[20px] py-[30px]">
            <div>
              Filtra per nutrizionista
            </div>
            <div>
              Totali: {filteredPatients.length}
            </div>
            <div className="w-[250px] h-[50px] relative">
              <PrimaryButton
                text={selectedNutritionist || "Seleziona nutrizionista"}
                pointer={true}
                disabled={false}
                onClick={() => {
                  setShowDropdown(!showDropdown)
                }}
              />
              {showDropdown && (
                <div className="absolute top-[60px] right-0 bg-white border border-solid border-gray-300 rounded shadow-lg z-10">
                  {
                    nutritionists.map((nutri, index) => (
                      <div
                        key={index}
                        className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() => { onNutritionistClick(`${nutri.data.name} ${nutri.data.family_name}`, nutri.email) }}
                      >
                        {nutri.data.name} {nutri.data.family_name}
                      </div>
                    ))
                  }
                  <div
                    className="bg-gray-100 px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={onResetNutritionistsClick}
                  >
                    Reset Nutrizionisti
                  </div>
                </div>
              )}
            </div>
          </div>
        }
        <div className="w-full h-[1px] bg-black" />
        <div className="space-y-[20px]">
          <PatientsHeader />
          <div className="w-full flex-1 space-y-[20px] pb-[20px]">
            {
              filteredPatients && filteredPatients.length > 0 ? (
                filteredPatients.map((patient, index) => (
                  <PatientCard
                    key={index}
                    patient={patient}
                    onClick={() => navigate(`single-customer?patient_id=${patient.user_id}`)}
                  />
                ))
              ) : <div>Nessun cliente trovato</div>
            }
          </div>
        </div>
      </div>
      {
        isLoading ? <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><LoadingSpinner /></div> : null
      }
    </div>
  )
}

export default Customers

const PatientsHeader = () => {
  return (
    <div className="w-full flex font-bold text-[14px] leading-[33px] px-[20px]">
      <div className="w-[50%] flex">
        <div className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
          Nome Cognome
        </div>
        <div className="w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
          E-mail
        </div>
      </div>
      <div className="w-[50%] flex">
        <div className="w-[calc(100%/3)] whitespace-nowrap overflow-hidden text-ellipsis text-holifya-blue">
          DNA Test (Swabcode)
        </div>
        <div className="w-[calc(100%/3)] whitespace-nowrap overflow-hidden text-ellipsis text-holifya-red">
          Esame sangue (più recente)
        </div>
        <div className="w-[calc(100%/3)] flex justify-end whitespace-nowrap overflow-hidden text-ellipsis">
          Acquisto
        </div>
      </div>
    </div>
  )
}

interface SearchProps {
  onClick: () => void,
  isLoading: boolean,
  filterInput: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  shouldShowResetButton: boolean,
  onResetClick: () => void,
}

const SearchBox: React.FC<SearchProps> = ({
  onClick,
  isLoading,
  filterInput,
  onChange,
  shouldShowResetButton,
  onResetClick,
}) => {
  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    onClick()
  }

  const isSubmitButtonDisabled = filterInput.length === 0

  // ********************************************************************************

  return (
    <div className="w-full rounded-[5px] shadow-platform px-[20px] py-[30px]">
      <form
        className="w-full h-full flex justify-between items-center"
        onSubmit={onSubmit}
      >
        <div className="w-[45%] h-full flex flex-col">
          <div className="font-bold text-[20px] leading-[33px]">
            Cerca
          </div>
          <div className="w-full">
            <TextInputElement
              label={"search-input"}
              placeholder={"Cerca per Nome, Cognome, E-mail o Codice identificativo"}
              inputValue={filterInput}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="w-[45%] space-y-[20px]">
          <div className="w-full h-[50px]">
            <PrimaryButton
              text={"Cerca"}
              pointer={!isSubmitButtonDisabled}
              disabled={isSubmitButtonDisabled}
              isLoading={isLoading}
            />
          </div>
          {
            shouldShowResetButton &&
            <div className="w-full h-[50px]">
              <SecondaryButton text="Resetta filtro" onClick={onResetClick} />
            </div>
          }
        </div>
      </form>
    </div>
  )
}

interface PatientCardProps {
  patient: Patient,
  onClick: () => void,
}

const PatientCard: React.FC<PatientCardProps> = ({
  patient,
  onClick,
}) => {

  return (
    <div className="w-full h-[90px] rounded-[5px] shadow-platform flex items-center p-[20px] cursor-pointer" onClick={onClick}>
      <div className="w-full h-full flex font-normal text-[16px] leading-[33px]">
        <div className="w-[50%] flex pr-[10px]">
          <div className="w-[50%] flex items-center font-bold text-[18px] leading-[33px] overflow-hidden whitespace-nowrap text-ellipsis pr-[10px]">
            {patient.user_name} {patient.user_family_name}
          </div>
          <div className="w-[50%] flex items-center overflow-hidden whitespace-nowrap text-ellipsis">
            {patient.email}
          </div>
        </div>
        <div className="w-[50%] flex">
          <div className="w-[calc(100%/3)] flex items-center text-holifya-blue font-bold overflow-hidden whitespace-nowrap text-ellipsis">
            {patient.swabcode || "--"}
          </div>
          <div className="w-[calc(100%/3)] flex items-center text-holifya-red font-bold overflow-hidden whitespace-nowrap text-ellipsis pl-[10px]">
            {patient.bloodcodes !== null ? (patient.bloodcodes[patient.bloodcodes.length - 1] || "--") : "--"}
          </div>
          <div className="w-[calc(100%/3)] flex items-center justify-end font-bold text-holifya-blue overflow-hidden whitespace-nowrap text-ellipsis pl-[10px]">
            {handlePurchasePriority(patient)}
          </div>
        </div>
      </div>
    </div>

  )
}
