import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import BackButton from "../../../components/buttons/BackButton"
import { BASE_URL } from "../../../utils/Constants"
import { ExpertRole } from "../../../utils/Enums"
import "./css/EmailRegistration.css"

const EmailRegistration = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const isAdminPage = location.pathname === "/email-registration-admin";

    const [name, setName] = useState("")
    const [familyName, setFamilyName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const [newsletterAccepted, setNewsletterAccepted] = useState(false)

    const onConfirmButtonClicked = () => {
        callRegisterAPI()
    }

    const role = isAdminPage ? ExpertRole.Admin : ExpertRole.Nutritionist

    const confirmButtonDisabled = name === "" || familyName === "" || email === "" || password === "" || confirmPassword === "" || password !== confirmPassword

    async function callRegisterAPI() {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/auth/register`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    password: password,
                    name: name,
                    family_name: familyName,
                    privacy_policy: newsletterAccepted,
                    role: role,
                    mobile_phone: "+390000000000"
                })
        })
        setIsLoading(false)
        if (!response.ok) {
            const responseJson = await response.json()
            console.log(responseJson)
            if (response.status === 422) {
                alert("form error")
            } else {
                console.log()
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
            navigate(`/email-registration-code`, { state: { email, role, newsletterAccepted, name, familyName, password } })
        }
    }

    return (
        <div className="App">
            <div className={`email-registration-page-buttons-row ${isLoading ? "opacity-30 pointer-events-none" : ""}`}>
                <div className="email-registration-page-buttons-container">
                    <BackButton
                        onClick={() => navigate("/login")}
                    />
                </div>
            </div>
            <div className={`email-registration-page-content ${isLoading ? "opacity-30 pointer-events-none" : ""}`}>
                <div className="email-registration-page-external-container">
                    <div className="email-registration-page-internal-container">
                        <div className="email-registration-heading">
                            Crea il tuo account
                        </div>
                        <div className="email-registration-copy">
                            Creando un account, dichiari di aver letto e compreso le{" "}
                            <a
                                className=""
                                href={"/assets/personal-data-info.pdf"}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={
                                    {
                                        textDecoration: "underline",
                                        color: "black",
                                        fontWeight: "600",
                                        cursor: "pointer",
                                    }}
                            >
                                Informazioni relative al trattamento dei dati personali
                            </a>.
                        </div>
                        <div className="email-registration-page-input-container">
                            <label className="email-registration-page-input-label">
                                Nome
                            </label>
                            <div className="email-registration-page-input-internal-container">
                                <input
                                    className="email-registration-page-input-field s16 w400"
                                    type="text"
                                    placeholder="Nome"
                                    onChange={
                                        (e) => setName(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="email-registration-page-input-container">
                            <label className="email-registration-page-input-label">
                                Cognome
                            </label>
                            <div className="email-registration-page-input-internal-container">
                                <input
                                    className="email-registration-page-input-field s16 w400"
                                    type="text"
                                    placeholder="Cognome"
                                    onChange={
                                        (e) => setFamilyName(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="email-registration-page-input-container">
                            <label className="email-registration-page-input-label">
                                Indirizzo e-mail
                            </label>
                            <div className="email-registration-page-input-internal-container">
                                <input
                                    className="email-registration-page-input-field s16 w400"
                                    type="text"
                                    placeholder="nome@esempio.com"
                                    onChange={
                                        (e) => setEmail(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="email-registraion-page-password-hint">
                            Una password valida è composta da almeno 8 caratteri di cui: almeno una Maiuscola, una minuscola, un numero e un simbolo speciale (!$%& ...)
                        </div>
                        <div className="email-registration-page-input-container">
                            <label className="email-registration-page-input-label">
                                Password
                            </label>
                            <div className="email-registration-page-input-internal-container">
                                <input
                                    className="email-registration-page-input-field s16 w400"
                                    type="password"
                                    placeholder="Password"
                                    onChange={
                                        (e) => setPassword(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="email-registration-page-input-container">
                            <label className="email-registration-page-input-label">
                                Conferma Password
                            </label>
                            <div className="email-registration-page-input-internal-container">
                                <input
                                    className="email-registration-page-input-field s16 w400"
                                    type="password"
                                    placeholder="Conferma Password"
                                    onChange={
                                        (e) => setConfirmPassword(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="email-registration-checkbox-row">
                            <div
                                className="email-registration-checkbox-container"
                                onClick={() => { setNewsletterAccepted(!newsletterAccepted) }}
                            >
                                <div className={`email-registration-checkbox-internal-element ${newsletterAccepted ? "" : "invisible"}`} />
                            </div>
                            <div className="email-registration-checkbox-copy">
                                Dichiaro di voler ricevere newsletter o comunicazioni commerciali da parte di Holifya.<br></br>
                                <a
                                    href={"/assets/newsletter.pdf"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={
                                        {
                                            textDecoration: "underline",
                                            color: "black",
                                            fontWeight: "600",
                                            cursor: "pointer",
                                        }}
                                >
                                    Informativa relativa alla newsletter
                                </a>
                            </div>
                        </div>
                        <button
                            className={`email-registration-page-confirm-button font-bold text-[14px] uppercase primary-action-color-background ${!confirmButtonDisabled ? "cursor-pointer" : ""}`}
                            disabled={confirmButtonDisabled}
                            onClick={onConfirmButtonClicked}
                        >
                            Continua
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailRegistration
