import DownloadButton from "../../../components/buttons/new/DownloadButton"
import { PdfType } from "../../../utils/Enums"
import { handleDate } from "../../../utils/Functions"
import { PdfExam } from "./PdfExam"

interface Props {
    exam: PdfExam,
    pdfType: PdfType,
}

const PdfCard: React.FC<Props> = ({
    exam,
    pdfType,
}) => {
    const handleFilename = () => {
        switch (pdfType) {
            case PdfType.CrossResults:
                return "CrossResults.pdf"
            case PdfType.HealthPlan:
                return "HealthPlan.pdf"
            case PdfType.Diet:
                return "Dieta.pdf"
            case PdfType.ShoppingList:
                return "Lista della spesa.pdf"
            case PdfType.TipsAndRecepes:
                return "Tips e ricette.pdf"
            case PdfType.EatingHabits:
                return "Eating habits.pdf"
            case PdfType.SeasonalFruitsAndVegetables:
                return "Frutta e verdura.pdf"
            default:
                return exam.filename
        }
    }

    const handleEmoji = () => {
        switch (pdfType) {
            case PdfType.DnaTest:
                return "🧬"
            case PdfType.BloodTest:
                return "🩸"
            case PdfType.CrossResults:
                return "🔀"
            case PdfType.HealthPlan:
                return "🌀"
            case PdfType.Diet:
                return "🥙"
            case PdfType.ShoppingList:
                return "🛒"
            case PdfType.TipsAndRecepes:
                return "🧑‍🍳"
            case PdfType.EatingHabits:
                return "🍽"
            case PdfType.SeasonalFruitsAndVegetables:
                return "🍎"
            case PdfType.Uploads:
                return "🗂️"
        }
    }

    const onDownloadClick = async () => {
        try {
            const response = await fetch(exam.url);
            if (!response.ok) throw new Error("Failed to download file");

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");

            a.href = url;
            a.download = handleFilename(); // Preserve the correct filename
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error("Download failed", error);
        }
    }

    const isPdf = exam.filename.toLowerCase().endsWith(".pdf");
    const isImage = /\.(jpg|jpeg|png)$/i.test(exam.filename.toLowerCase());

    return (
        <div className="w-full h-[516px] rounded-[10px] relative shadow-platform flex-shrink-0 md:w-[362px] md:h-[527px]">
            {isPdf ? (
                <iframe
                    className="w-full h-[75%] border-none m-[0] p-[0] block rounded-t-[10px]"
                    src={exam.url + "#toolbar=0"}
                />
            ) : isImage ? (
                <img
                    className="w-full h-[75%] object-contain bg-gray-200"
                    src={exam.url}
                    alt="Immagine non caricata"
                    onError={(e) => {
                        (e.target as HTMLImageElement).src = "/images/placeholders/jpg-placeholder.png";
                    }}
                />
            ) : (
                <div className="w-full h-[75%] flex items-center justify-center bg-gray-300">
                    <span className="text-gray-600">File non supportato</span>
                </div>
            )}
            <div className="w-full h-[25%] m-0 p-[20px] flex items-center justify-between">
                <div className="flex-1 max-w-[70%]">
                    <div className="font-semibold text-[12px] leading-[26px]">
                        {handleDate(exam.uploaded_at)}
                    </div>
                    <div
                        className="font-semibold text-[16px] leading-[26px] truncate"
                        title={handleFilename()}
                    >
                        {handleFilename()}
                    </div>
                </div>
                <div className="flex-shrink-0">
                    <DownloadButton onClick={onDownloadClick} />
                </div>
            </div>
            <div className="w-full h-[75%] bg-transparent absolute top-[0] left-[0] flex items-start justify-center">
                <div className="w-[90%] mt-[10px] flex justify-end">
                    <div className="w-[30px] h-[30px] rounded-[5px] bg-white flex items-center justify-center">
                        {handleEmoji()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PdfCard
