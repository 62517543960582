import { BloodTestTracker } from "../api-objects/BloodTestTracker";
import { DnaTestTracker } from "../api-objects/DnaTestTracker";
import { BASE_URL, requestOptionsGET } from "../Constants";
import { ErrorWithMessageCallback, GetTrackerByUserIdSuccessCallback, SuccessWithTrackerCallback } from "./callback-helpers";

export async function getTrackerByUserId(
    userId: string,
    idToken: string,
    onSuccess: GetTrackerByUserIdSuccessCallback,
    onError: ErrorWithMessageCallback,
    handleSessionExpiry?: () => void,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const trackers: DnaTestTracker[] = await response.json()
            onSuccess(trackers)
        } else if (response.status === 401 && handleSessionExpiry) {
            handleSessionExpiry();
        } else {
            const errorMessage = response.statusText || "Dna Test not found";
            onError(errorMessage);
        }
    } catch (error) {
        console.error("Get tracker failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getBloodTrackerByUserId(
    userId: string,
    idToken: string,
    onSuccess: SuccessWithTrackerCallback,
    onError: ErrorWithMessageCallback,
    handleSessionExpiry?: () => void,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/blood-track/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const trackers: BloodTestTracker[] = await response.json()
            onSuccess(trackers)
        } else if (response.status === 401 && handleSessionExpiry) {
            handleSessionExpiry();
        } else {
            const errorMessage = response.statusText || "Blood exam not found";
            onError(errorMessage);
        }
    } catch (error) {
        console.error("Get blood tracker failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}