import { useState } from "react"
import { useNavigate } from "react-router-dom"
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import { loginApi } from "../../../utils/apis/auth-service"
import { ENVIRONMENT } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { Environment } from "../../../utils/Enums"
import LoginBox from "./components/LoginBox"

const LoginPage = () => {

    const navigate = useNavigate()
    const { login } = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [passwordErrorText, setPasswordErrorText] = useState("")

    const onCreateAccountClick = () => {
        navigate("/email-registration", { state: { newsletterAccepted: false } })
    }

    const handleLoginInputsSubmit = (inputData: { [key: string]: string }) => {
        const email = inputData.email
        const password = inputData.password
        callLogin(email, password)
    }

    const callLogin = (email: string, password: string) => {
        localStorage.clear()
        setIsLoading(true)
        setPasswordErrorText("")
        loginApi(
            email,
            password,
            (result) => {
                setIsLoading(false)
                login(
                    result.idToken,
                    result.userId,
                    result.accessToken,
                    result.refreshToken,
                    result.expiryTime,
                    result.role,
                )
                navigate(`/fetch-user-data`)
            },
            (error) => {
                setPasswordErrorText("Indirizzo e-mail o password errati")
                setIsLoading(false)
            }
        )
    }

    // ********************************************************************************

    return (
        <div className={`App relative justify-start`}>
            <div className={`w-[90%] h-[80px] flex items-center ${ENVIRONMENT === Environment.DEVELOP ? "justify-between" : "justify-end"}`}>
                {
                    ENVIRONMENT === Environment.DEVELOP && <button className="w-[159px] h-[55px] bg-holifya-blue rounded-[5px] text-white font-bold uppercase">
                        Expert
                    </button>
                }
                <div className="w-[159px] h-[55px]">
                    <SecondaryButton
                        text="Crea un account"
                        onClick={onCreateAccountClick}
                        fontSize={12}
                    />
                </div>
            </div>
            <div className="w-full h-[calc(100%-130px)] sm:h-[calc(100%-180px)] flex flex-col items-center justify-start sm:justify-center mt-[30px] sm:mt-[20px] overflow-y-auto scrollbar-hide">
                <div className={`w-[328px] p-[20px] flex bg-white`}>
                    <LoginBox
                        onSubmit={handleLoginInputsSubmit}
                        passwordError={passwordErrorText}
                    />
                </div>
            </div>
            {
                isLoading && <LoadingSpinner />
            }
        </div>
    )
}

export default LoginPage
