import { BASE_URL, requestOptionsGET } from "../Constants";

export async function getStatsByEmail(email: string, idToken: string): Promise<any> {
    try {
        const response = await fetch(`${BASE_URL}/api/temp-stats/${email}`, requestOptionsGET(idToken));

        if (response.ok) {
            const rows = await response.json();
            return rows;
        } else {
            // Log error details for non-200 status
            console.error("Error response:", await response.text());
            return [];
        }
    } catch (error) {
        console.log("Error fetching statistics:", error);
        return [];
    }
}