import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import BackButton from "../../../../components/buttons/new/BackButton"
import { HealthPlan } from "../../../../utils/api-objects/meal-plan"
import { Patient } from "../../../../utils/api-objects/Patient"
import { getHealthPlansById } from "../../../../utils/apis/plan-service"
import { useAuth } from "../../../../utils/context/AuthContext"
import { usePatients } from "../../../../utils/context/PatientsContext"
import { HealthPlanSection } from "./components/HealthPlanSection"

const OldHealthPlanPage = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const [searchParams] = useSearchParams()
    const healthPlanId = searchParams.get("id")
    const spanDate = searchParams.get("span-date")
    const patientId = searchParams.get("patient-id")
    const navigate = useNavigate()

    const { patientsState } = usePatients()
    const patients = patientsState.patients

    const [patient, setPatient] = useState<Patient | null>(null);
    const [isGeneralLoading, setIsGeneralLoading] = useState(true);

    const [healthPlan, setHealthPlan] = useState<HealthPlan>()

    useEffect(() => {
        if (!patients) {
            setIsGeneralLoading(false);
            return;
        }
        const foundPatient = patients.find((p) => p.user_id === patientId);
        if (!foundPatient) return
        setPatient(foundPatient);
        setIsGeneralLoading(false)

        if (!patientId || !idToken) return
    }, [patients, patientId])

    const callGetHealthPlanById = async () => {
        if (!healthPlanId || !idToken) return
        try {
            const healthPlan = await getHealthPlansById(healthPlanId, idToken)
            healthPlan && setHealthPlan(healthPlan)
        } catch {
            console.log("Health Plan non trovato")
        }
    }

    useEffect(() => {
        callGetHealthPlanById()
    }, [healthPlanId, idToken])

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Informazioni sul cliente non trovate
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    if (!healthPlan) {
        return (
            <div></div>
        )
    }

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
            <div className="w-full  max-w-[1068px] space-y-[20px]">
                <BackButton
                    text={`Torna a Health Plan passati`}
                    onClick={() => navigate(`/platform-experts/customers/single-customer/view-health-plan-page/health-plan-history?patient_id=${patientId}`)}
                />
                <div className="">
                    <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                    <div className="font-bold text-[20px] leading-[33px]">Health Plan passati</div>
                    <div className="font-bold text-[14px] leading-[33px]">{spanDate}</div>
                </div>
                <HealthPlanSection healthPlan={healthPlan} showUpdate={false}/>
            </div>

        </div>
    )
}

export default OldHealthPlanPage
