import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../../components/buttons/new/BackButton";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { HealthPlanPdfType } from "../../../../utils/api-objects/meal-plan";
import { Patient } from "../../../../utils/api-objects/Patient";
import { getHealthPlanPdf } from "../../../../utils/apis/plan-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { usePatients } from "../../../../utils/context/PatientsContext";
import { PdfType } from "../../../../utils/Enums";
import PdfCard from "../../components/PdfCard";
import { PdfExam } from "../../components/PdfExam";

const HealthPlanPdfsPage = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get("patient_id");
    const healthPlanId = searchParams.get("health_plan_id");
    const type = searchParams.get("type");

    const { patientsState } = usePatients()
    const patients = patientsState.patients

    const navigate = useNavigate()

    const [patient, setPatient] = useState<Patient | null>(null);
    const [isGeneralLoading, setIsGeneralLoading] = useState(true);

    const [pdfs, setPdfs] = useState<PdfExam[]>()

    useEffect(() => {
        if (!patients) {
            setIsGeneralLoading(false);
            return;
        }
        const foundPatient = patients.find((p) => p.user_id === patientId);
        if (!foundPatient) return
        setPatient(foundPatient);
        setIsGeneralLoading(false)

    }, [patients, patientId])

    const getHealthPlanUrls = async () => {
        if (!patientId || !idToken) return
        const folder = `${healthPlanId}/${type}`
        try {
            const urls = await getHealthPlanPdf(patientId, idToken, folder)
            setPdfs(urls)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getHealthPlanUrls()
    }, [])

    if (isGeneralLoading || !pdfs) {
        return (
            <LoadingSpinner />
        );
    }

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Informazioni sul cliente non trovate
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
            <div className="w-full  max-w-[1068px] space-y-[20px] pb-[20px]">
                <BackButton
                    text={`Torna a ${patient.user_name} ${patient.user_family_name}`}
                    onClick={() => navigate(`/platform-experts/customers/single-customer/view-health-plan-page?patient_id=${patientId}`)}
                />
                <div className="w-full flex items-center justify-between">
                    <div className="">
                        <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                        <div className="font-bold text-[35px] leading-[35px]">{type ===  HealthPlanPdfType.Materials ? "🗂️ Materiali di approfondimento" : "📄 Prescrizioni"}</div>
                    </div>
                </div>
                {
                    pdfs.map((pdf, index) => (
                        <PdfCard key={index} exam={pdf} pdfType={PdfType.Uploads}/>
                    ))
                }
            </div>
        </div>
    )
}

export default HealthPlanPdfsPage
