import { useRef } from "react";

export const UploadSection: React.FC<{
    title: string;
    description: string;
    onFilesSelect: (file: File[]) => void;
    onRemoveFile: (index: number) => void;
    selectedFiles: File[];
}> = ({ title, description, onFilesSelect, onRemoveFile, selectedFiles }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const validFiles = Array.from(files).filter((file) => file.type === "application/pdf");
            if (validFiles.length > 0) {
                onFilesSelect(validFiles);
            } else {
                alert("Per favore carica solo file PDF.");
            }
        }
    };

    return (
        <div className="w-full rounded-[20px] bg-[#F5F5F5] p-[20px] space-y-[10px]">
            <div className="w-full flex items-center justify-between font-bold text-[16px] leading-[33px]">
                <div>{title}</div>
                <div
                    className="text-holifya-blue cursor-pointer"
                    onClick={() => fileInputRef.current?.click()}
                >
                    Carica
                </div>
            </div>

            {/* Hidden file input */}
            <input
                type="file"
                ref={fileInputRef}
                accept=".pdf"
                style={{ display: "none" }}
                multiple
                onChange={handleFileChange}
            />

            <div className="text-[16px] leading-[33px]">
                {selectedFiles.length > 0 ? (
                    <div className="space-y-[10px]">
                        {selectedFiles.map((file, index) => (
                            <div
                                key={index}
                                className="rounded-[4px] font-normal text-[16px] leading-[33px] bg-[#E7E7E7] flex justify-between items-center p-[10px]"
                            >
                                <div>{file.name}</div>
                                <button
                                    className="font-bold text-[14px] leading-[33px]"
                                    onClick={() => onRemoveFile(index)}
                                >
                                    Elimina
                                </button>
                            </div>
                        ))}
                    </div>
                ) : (
                    description
                )}
            </div>
        </div>
    );
};