import { PdfExam } from "../../pages/platform/components/PdfExam";
import { Answer } from "../api-objects/Answer";
import { BloodTestTracker } from "../api-objects/BloodTestTracker";
import { CalendlyAppointment } from "../api-objects/CalendlyAppointment";
import { CalendlyExpert } from "../api-objects/CalendlyExpert";
import { DnaTestTracker } from "../api-objects/DnaTestTracker";
import { MealPlan } from "../api-objects/meal-plan";
import { Patient } from "../api-objects/Patient";
import { Question } from "../api-objects/Question";
import { SortingItem } from "../api-objects/SortingItem";
import { SurveyCompleted } from "../api-objects/SurveyCompleted";
import { ExpertRole } from "../Enums";

/**
 * Type for the callback function invoked upon successful login.
 * @param result - An object containing login details.
 * @param result.idToken - The ID token received from the server.
 * @param result.accessToken - The access token received from the server.
 * @param result.userId - The unique identifier of the user.
 */
export type LoginSuccessCallback = (result: { idToken: string, accessToken: string, userId: string, refreshToken: string, expiryTime: number, role: ExpertRole }) => void;

/**
 * Type for the callback function invoked upon login error.
 * @param error - A string describing the error that occurred.
 * @param showChangePasswordDialog - Optional flag to indicate if the change password dialog should be shown.
 */
export type LoginErrorCallback = (error: string, showChangePasswordDialog?: boolean) => void;

/**
 * Type for the callback function invoked upon generic success.
 */
export type GenericSuccessCallback = (success: any) => void;

/**
 * Type for the callback function invoked a generic success with message.
 * @param error - A string describing the success that occurred.
 */
export type SuccessWithMessageCallback = (success: string) => void;

/**
 * Type for the callback function invoked a generic error.
 * @param error - A string describing the error that occurred.
 */
export type ErrorWithMessageCallback = (error: string) => void;

/**
 * Type for the callback function invoked upon success retrieving a medical user.
 */
export type GetMedicalUserSuccessCallback = (user: CalendlyExpert) => void;

/**
 * Type for the callback function invoked upon success retrieving patients.
 */
export type GetPatientsSuccessCallback = (patients: Patient[]) => void;

/**
 * Type for the callback function invoked upon success retrieving calendly appointments.
 */
export type GetAppointmentsSuccessCallback = (appointments: CalendlyAppointment[]) => void;

/**
 * Type for the callback function invoked upon success retrieving tracker.
 * @param trackers - The list of tracker objects.
 */
export type GetTrackerByUserIdSuccessCallback = (trackers: DnaTestTracker[]) => void;

/**
 * Type for the callback function invoked upon success retrieving blood tracker.
 * @param tracker - The tracker object.
 */
export type SuccessWithTrackerCallback = (tracker: BloodTestTracker[]) => void;

/**
 * Type for the callback function invoked upon get all exams success.
 */
export type GetExamsSuccessCallback = (exams: PdfExam[]) => void;

/**
 * Type for the callback function invoked upon get all questions success.
 */
export type GetQuestionsSuccessCallback = (questions: Question[]) => void;

/**
 * Type for the callback function invoked upon get question success.
 */
export type GetAnswersSuccessCallback = (answers: Answer[]) => void;

/**
 * Type for the callback function invoked upon get meal plan success.
 */
export type GetMealPlanSuccessCallback = (mealPlan: MealPlan[]) => void;

/**
 * Type for the callback function invoked upon get sorting items success.
 */
export type GetSortingItemsSuccessCallback = (items: SortingItem[]) => void;

export type GetSurveysCompletedCallback = (surveys: SurveyCompleted[]) => void;

export async function handleApiResponse<T>(
    response: Response,
    onSuccess: (data: T) => void,
    onError: (error: string) => void,
    handleSessionExpiry?: () => void
) {
    if (response.ok) {
        const data: T = await response.json();
        onSuccess(data);
    } else if (response.status === 401 && handleSessionExpiry) {
        handleSessionExpiry();
    } else if (response.status === 404) {
        onSuccess([] as T); // Handle 404 as empty data
    } else {
        onError(`Error: ${response.status} - ${response.statusText}`);
    }
}
